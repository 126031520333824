@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.AppHeader {
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  height: 100px;
  min-height: 99px;
  padding: 0 40px;
  position: relative;
}

.AppHeader--wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #E2E8F0;
  position: fixed;
  width: 100%;
  transition: top 0.3s ease, background-color 0.3s ease;
  z-index: 10000000;
}

.AppHeader * {
  font-family: var(--design-body-text-font);
}

.AppHeader__logo {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 25%;
  cursor: pointer;
  z-index: 100000
}

.AppHeader__logo.template--ModernMarvel {
  margin-left: 50%;
  transform: translateX(-50%);
}

.AppHeader__logo img {
  max-width: 150px;
  margin: 0 auto;
  max-height: calc(100% - 20px);
}

.AppHeader__menu {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.storeName {
  align-self: center;
  padding-right: 20px;
  user-select: none;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--design-secondary-color);
}

.AppHeader__menu > a {
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  color: var(--design-secondary-color);
  font-weight: 500;
  letter-spacing: 0.9px;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
}

.AppHeader__menu__activeTitle {
  font-family: var(--design-body-text-font);
  color: var(--design-primary-color);
  font-weight: 600;
  font-size: 14px;
  padding: 4px 8px;
  user-select: none;
  cursor: default;
}

.AppHeader__search {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 50%;
  max-width: 45vw;
  width: 100%;
  transform: translateX(-50%);
}

#AppHeader__SearchContainer {
  animation: fadein 1s;
}

.AppHeader__profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.AppHeader__profileUser {
  width: 72px;
}

.AppHeader__profile > div {
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.ActivationSuccessContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(440px, 90%) !important;
  background: #fff;
  border: none;
  outline: none;
  box-shadow: 24px;
  padding: 40px;
  border-radius: 12px;
  gap: 12px;
}

.ActivationSuccessContainer__closeBtn {
  position: absolute !important;
  right: 1%;
  top: 1%;
  color: var(--design-secondary-color) !important;
}

.ActivationSuccessContainer__successImg {
  color: #34D399;
  font-size: 45px !important;
  width: 100% !important;
}

.ActivationSuccessContainer__title {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: var(--design-secondary-color);
  text-align: center;
  font-family: var(--design-header-text-font) !important;
}

.ActivationSuccessContainer__description {
  font-size: 16px !important;
  color: var(--design-secondary-color) !important;
  text-align: center !important;
  font-family: var(--design-body-text-font) !important;
}

.ActivationSuccessContainer__button {
  background: var(--design-primary-color) !important;
  padding: 10px 16px 10px 16px;
  border-radius: 8px !important;
  width: 100%;
  font-family: var(--design-body-text-font) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 32px;
  color: #FFFFFF !important;
  text-transform: none !important;
  margin-top: auto;
  height: 56px;
  letter-spacing: 0.02em;
}

@media (max-width: 768px) {
  .AppHeader__profile > div {
    padding: 0;
  }

  .AppHeader__logo img {
    max-width: 119px;
    max-height: calc(100% - 10px);
  }

  .AppHeader__logo img.portrait {
    max-height: calc(100% - 20px);
  }

  .AppHeader__logo {
    max-width: 45%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
  }

  .storeName {
    padding-right: 0;
  }

  .ActivationSuccessContainer {
    padding: 30px 20px;
  }
}

.AppHeader--mobile {
  justify-content: space-between;
  height: 64px;
  min-height: 74px;
  padding: 0 20px;
  position: relative;
  position: fixed;
  width: calc(100% - 40px);
  z-index: 1000000;
  transition: top 0.3s ease, background-color 0.3s ease;
}

.AppHeader--mobile.portrait {
  height: 130px;
  min-height: 129px;
}

.AppHeader__menuMobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppHeader__menuMobile__title {
  color: var(--design-secondary-color);
  font-weight: 700;
  font-size: 14px;
  padding: 20px;
  margin: 0;
  place-self: center;
  user-select: none;
  cursor: default;
}

.AppHeader__menuMobile__drawerTop {
  position: relative;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.AppHeader__menuMobile__profileSettings {
  margin-top: auto;
  margin-bottom: 64px;
}

.AppHeader__menuMobile__currencySelect {
  display: flex !important;
}

.AppHeader__menuMobile_circularProgress {
  margin: 16px auto;
}

.AppHeader__menuMobile__activeTitle {
  color: var(--design-secondary-color);
  opacity: 0.6;
  font-weight: 700;
  font-size: 12px;
  padding: 0 16px;
  width: 100%;
  background-color: #fff;
  margin-bottom: 6px;
  z-index: 1;
}

.AppHeader__search__itemImage {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.AppHeader__shoppingCartContainer {
  padding: 16px 0px;
  margin-left: 8px;
}

.AppHeader__shoppingCartIcon {
  padding: 10px !important;
  cursor: pointer;
}

.AppHeader__shoppingCartMobContainer {  
  margin: 2px 0;
}

.AppHeader__shoppingCartBadge {
  background-color: var(--design-primary-color);
  color: #fff;
}